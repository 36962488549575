import {
  ADD_INVOICE,
  SET_INVOICE_LIST,
  SET_LOADING,
  SET_CUSTOMER,
}
  from "../types"

const initialState = {
  invoicesList: [],
  contact: {},
  loading: false,
}

export function invoicesReducer(state = initialState, action) {
  // Reducers usually look at the type of action that happened
  // to decide how to update the state
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload }
    case ADD_INVOICE: {
      if (!state.invoicesList.map(i => i.invoiceNumber).includes(action.payload.invoiceNumber)) {
        const list = [...state.invoicesList, action.payload];
        return { ...state, invoicesList: list, contact: {name: list[0].entity} }
      } else {
        return state
      }
    }
    case SET_CUSTOMER:
      return { ...state, contact: action.payload }
    case SET_INVOICE_LIST: {
      return { ...state, invoicesList: action.payload }
    }
    default:
      // If the reducer doesn't care about this action type,
      // return the existing state unchanged
      return state
  }
}