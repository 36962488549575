import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

const ZiwoMaintenance = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
      <img src={"/images/maintenance.gif"} alt="maintenance logo" style={{ height: "200px" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Typography sx={{ fontSize: "30px", fontWeight: "500" }}>
          <FormattedMessage id="maintenance_header" />
        </Typography>
        <Typography sx={{ fontSize: "30px", fontWeight: "500" }}>
          <FormattedMessage id="maintenance_body" />
        </Typography>
        <Typography sx={{ fontSize: "20px", color: "primary.light", marginTop: "12px", fontWeight: "bold" }}>
          <FormattedMessage
            id="maintenance_hours"
            values={{
              date: props.date,
              startTime: props.startTime,
              endTime: props.endTime,
            }}
          />
        </Typography>
        <Typography sx={{ fontSize: "20px", fontWeight: "300", marginTop: "12px" }}>
          <FormattedMessage id="ziwo_team_signature" />
        </Typography>
      </Box>
    </Box>
  );
};

export default ZiwoMaintenance;
