import {
  Box,
  Stack,
  Typography,
  Button
} from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react"
import { FormattedMessage } from 'react-intl';

const Success = () => {

  let navigate = useNavigate();
  const { checkoutResponse } = useSelector((state) => state.paymentReducer)
  useEffect(() => {
    if (!checkoutResponse.id)
      navigate("/")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      sx={{
        minHeight: "85vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
        textAlign: "center",
      }}>
      <Stack
        spacing={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}>
        <img
          src={"/images/success.png"}
          alt="success"
          style={{ height: "75px" }} />
        <Typography sx={{
          fontSize: "40px",
          fontFamily: "Raleway"
        }}>
          <FormattedMessage id="paid"/>
        </Typography>
        <Box>
          <Typography
            sx={{ fontSize: "24px" }}>
            <FormattedMessage id="paid_successfully"/>
          </Typography>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "600"
            }}>
            <FormattedMessage id="aswat"/>
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              opacity: "0.56",
              fontSize: "24px"
            }}>
            {moment(checkoutResponse?.created).format("hh:mm DD MMMM YYYY")}
          </Typography>
          <Typography
            sx={{
              opacity: "0.56",
              fontSize: "24px"
            }}>
            <FormattedMessage id="transaction_id"/> {checkoutResponse?.id}
          </Typography>
          <Typography
            sx={{
              opacity: "0.56",
              fontSize: "24px"
            }}>
            <FormattedMessage id="status_success"/>
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              opacity: "0.56",
              fontSize: "24px"
            }}>
            <FormattedMessage id="payment_success" values={{br: <br/>}}/>
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={{
            fontSize: "20px",
            textTransform: 'none',
            minWidth: "30%"
          }}
          onClick={() => navigate("/")}>
          <FormattedMessage id="go_back_button"/>
        </Button>
      </Stack>
    </Box>
  )
}

export default Success;