import {
  Box,
  Typography,
  Stack,
  TextField,
  Button
} from '@mui/material';
import {
  pay,
  completeInvoice
} from '../api/checkout';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import {
  useState,
  useEffect
} from "react"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  SET_CHECKOUT_RESPONSE,
  SET_CUSTOMER,
  SET_INVOICE_LIST,
  SET_LOADING
} from '../redux/types';
import Loader from '../components/loader';
import { formatter } from "../redux/actions/paymentActions";
import { FormattedMessage } from 'react-intl';
import { setPaymentIntent } from '../redux/actions/creditAction';

const Payment = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    contact,
    invoicesList,
    loading,
  } = useSelector((state) => state.invoicesReducer)

  const {
    paymentIntent
  } = useSelector((state) => state.paymentReducer)

  const [state, setState] = useState({
    number: "",
    cvv: "",
    expiry: ""
  })
  const [errors, setErrors] = useState({
    number: "",
    cvv: "",
    expiry: ""
  })

  useEffect(() => {
    if (invoicesList.length === 0)
      navigate("/")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  window.addEventListener('message', function (ev) {
    if (ev.data === '3DS-authentication-complete')
      dispatch(setPaymentIntent(localStorage.getItem('ziwo-qp-payment_intent')))
  }, false);

  // handle 3D secure payment result
  useEffect(() => {
    const iframe = document.getElementById("3d-auth");
    if (iframe) {
      iframe.remove();
      completePayment()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIntent])


  const completePayment = async () => {
    const response = await completeInvoice({
      payment_intent: paymentIntent,
      invoicesList,
      contactName: contact.name,
    })

    if (response.result === true) {
      dispatch({ type: SET_CUSTOMER, payload: false })
      dispatch({ type: SET_INVOICE_LIST, payload: [] })

      dispatch(setPaymentIntent(null))
      console.log({ response })
      dispatch({ type: SET_CHECKOUT_RESPONSE, payload: response.content.checkout })
      navigate("/success")
    } else {
      toast(
        <FormattedMessage id={response.info.message} />,
        { type: 'error' }
      );
    }
    dispatch({ type: SET_LOADING, payload: false })
  }

  const handleChangeNumber = (event) => {
    let noSpaces = event.target.value.replaceAll(' ', '');
    if (!isNaN(+noSpaces) && noSpaces.length <= 16) {
      let val = event.target.value.trim();
      if (noSpaces.length % 4 === 1 && noSpaces.length < 16) {
        val = val.substring(0, val.length - 1)
          + " " +
          val.substring(val.length - 1, val.length)
      }
      setState({
        ...state,
        number: val
      })
      setErrors({
        ...errors,
        number: ""
      })
    }
  }

  const handleChangeExpiry = (event) => {
    let val = event.target.value;
    if (!isNaN(+val.replaceAll('/', '')) && val.length <= 5) {
      if (val.length === 3 && !val.includes('/')) {
        val = val.substring(0, val.length - 1)
          + "/" +
          val.substring(val.length - 1, val.length)
      }
      setState({
        ...state, expiry: val
      })
      setErrors({
        ...errors,
        expiry: ""
      })
    }
  }

  const handleChangeCvv = (event) => {
    let val = event.target.value;
    if (!isNaN(+val) && val.length <= 3) {
      setState({
        ...state, cvv: val
      })
      setErrors({
        ...errors,
        cvv: ""
      })
    }
  }

  const handlePay = async () => {
    if (!state.number || !state.expiry || !state.cvv) {
      setErrors({
        number: state.number ? "" : "required_field",
        expiry: state.expiry ? "" : "required_field",
        cvv: state.cvv ? "" : "required_field"
      })
      return;
    }
    let noSpaces = state.number.replaceAll(' ', '');
    if (noSpaces.length < 16) {
      setErrors({
        number: 'invalid_card_number',
      })
      return;
    }
    let response;
    dispatch({ type: SET_LOADING, payload: true })

    response = await pay({
      ...state,
      number: state.number.replaceAll(' ', ''),
      amount: invoicesList.reduce((acc, obj) => {return acc + parseFloat(obj.currencyBalance)}, 0),
      invoicesList,
      contactName: contact.name,
      currency: invoicesList[0].currency
    })

    if (response.result === true) {
      if (response.content.checkout.next_action && response.content.checkout.next_action.redirect_to_url) {
        // window.location = response.content.checkout.next_action.redirect_to_url.url;
        var iframe = document.createElement('iframe');
        iframe.src = response.content.checkout.next_action.redirect_to_url.url;
        iframe.width = "80%";
        iframe.height = "80%";
        iframe.id = "3d-auth";
        iframe.style.position = "absolute"
        iframe.style.top = "10vh"
        iframe.style.left = "10vw"
        iframe.style.border = "2px solid #0000003d";
        iframe.style.borderRadius = "16px";
        iframe.style.zIndex = "20"
        document.getElementById("container").appendChild(iframe);
      } else {
        dispatch({ type: SET_CUSTOMER, payload: false })
        dispatch({ type: SET_INVOICE_LIST, payload: [] })
        dispatch({ type: SET_CHECKOUT_RESPONSE, payload: response.content.checkout })
        navigate("/success")
      }
    } else {
      toast(
        response.info.message,
        { type: 'error' }
      );
    }
    dispatch({ type: SET_LOADING, payload: false })
  }

  return (
    <Box id="container"
      sx={{
        minHeight: "86vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}>
      {loading && <Loader />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "75vh",
        }}>
        <Stack spacing={5} sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}>
          <img src={"/images/ziwo-logo.png"}
            alt="logo"
            style={{ height: "75px" }} />
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: "30%"
          }}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "500",
                width: "100%",
                textAlign: "center"
              }}>
              <FormattedMessage id="payment_details" />
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row"
              }}>
              <Typography sx={{
                fontSize: "14px",
                fontWeight: "300",
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}>
                <FormattedMessage id="name" />
              </Typography>
              <Typography
                sx={{
                  fontWeight: "500",
                  mx: 2,
                  fontSize: "16px"
                }}>
                {contact.name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row"
              }}>
              <Typography sx={{
                fontSize: "14px",
                fontWeight: "300",
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}>
                <FormattedMessage id="payment_amount" />
              </Typography>
              <Typography
                sx={{
                  fontWeight: "500",
                  mx: 2,
                  fontSize: "16px"
                }}>
                {formatter(invoicesList.reduce((acc, obj) => {return acc + parseFloat(obj.currencyBalance)}, 0))} {invoicesList[0]?.currency}
              </Typography>
            </Box>
          </Box>
          <Box style={{ minWidth: "30%" }}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "500",
                width: "100%",
                textAlign: "center"
              }}>
              <FormattedMessage id="payment_options" />
            </Typography>
            <TextField
              variant="standard"
              type="text"
              onChange={handleChangeNumber}
              value={state.number}
              helperText={errors.number ? <FormattedMessage id={errors.number} /> : ""}
              label={<FormattedMessage id="card_number" />}
              sx={{
                width: "100%",
                color: "primary.light",
                fontSize: "16px",
                input: { color: '#AD3278' },
                my: 3
              }}
              FormHelperTextProps={{ sx: { color: "red" } }} />
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}>
              <TextField
                variant="standard"
                value={state.expiry}
                onChange={handleChangeExpiry}
                helperText={errors.expiry ? <FormattedMessage id={errors.expiry} /> : ""}
                label={<FormattedMessage id="expiry_date" />}
                sx={{
                  width: "45%",
                  color: "primary.light",
                  fontSize: "16px",
                  input: { color: '#AD3278' }
                }}
                FormHelperTextProps={{ sx: { color: "red" } }} />
              <TextField
                variant="standard"
                type="text"
                value={state.cvv}
                onChange={handleChangeCvv}
                helperText={errors.cvv ? <FormattedMessage id={errors.cvv} /> : ""}
                label={<FormattedMessage id="security_code" />}
                sx={{
                  width: "45%",
                  color: "primary.light",
                  fontSize: "16px",
                  input: { color: '#AD3278' }
                }}
                FormHelperTextProps={{ sx: { color: "red" } }} />
            </Box>
          </Box>
          <Button
            variant="contained"
            sx={{
              fontSize: "20px",
              textTransform: 'none',
              minWidth: "30%",
              ":disabled": { background: "#9C2669B3", color: "white" }
            }}
            onClick={handlePay}
            disabled={!state.number || state.expiry.length < 5 || state.cvv.length < 3}
          >
            <FormattedMessage id="pay" />
          </Button>
        </Stack>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column"
        }}>
        <Typography
          sx={{
            fontSize: "14px",
            mb: 1
          }}>
          <FormattedMessage id="accepted_cards" />
        </Typography>
        <img src={"/images/pay-by.png"}
          alt="success" style={{
            objectFit: "scale-down",
            height: "20px"
          }} />
      </Box>
    </Box>
  )
}

export default Payment;