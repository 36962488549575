import { Box, Typography, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FormattedMessage } from "react-intl";
import ZiwoHeader from "./components/ziwoHeader";
import ZiwoMaintenance from "./components/maintenance";
import { isInMaintenance } from "./index.js";

const App = () => {
  const matches = useMediaQuery("(min-width:440px)");

  const maintenance = {
    date: "June 5, 2024,",
    startTime: "5 pm",
    endTime: "8 pm (GST / UTC+04:00)",
  };

  return (
    <Box sx={{ minHeight: "86vh", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
      <Stack
        spacing={5}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingY: "4rem",
          paddingBottom: "3.2rem",
        }}>
        <ZiwoHeader />
        {isInMaintenance ? (
          <ZiwoMaintenance date={maintenance.date} startTime={maintenance.startTime} endTime={maintenance.endTime} />
        ) : (
          <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            <Link to="/pay-invoice" underline="none">
              <Box
                sx={{
                  bgcolor: "primary.light",
                  display: "flex",
                  flexDirection: "column",
                  width: matches ? "420px" : "250px",
                  height: "230px",
                  justifyContent: "space-around",
                  border: 3,
                  borderRadius: "16px",
                  borderColor: "primary.light",
                  padding: "0 20px",
                  m: 2,
                }}>
                <img src="/images/arrow-white.png" style={{ display: "flex", alignSelf: "end" }} width="30" height="30" alt="back arrow icon" />
                <img src="/images/pay-invoice.png" width="48" height="76" alt="pay invoice icon" />
                <Typography sx={{ color: "white", fontSize: "36px" }}>
                  <FormattedMessage id="pay_invoices_button" />
                </Typography>
              </Box>
            </Link>
            <Link to="/top-up-credit" underline="none">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: matches ? "420px" : "250px",
                  height: "230px",
                  justifyContent: "space-around",
                  border: 3,
                  borderRadius: "16px",
                  borderColor: "primary.light",
                  padding: "0 20px",
                  m: 2,
                }}>
                <img src="/images/arrow.png" style={{ display: "flex", alignSelf: "end" }} width="30" height="30" alt="back arrow icon" />
                <img src="/images/top-up-credit-icon.png" width="70" height="70" alt="top up credit icon" />
                <Typography sx={{ color: "primary.light", fontSize: "36px" }}>
                  <FormattedMessage id="topup_credit_button" />
                </Typography>
              </Box>
            </Link>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default App;
