import { Select, MenuItem, Box, InputAdornment } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { setLaguage } from '../redux/actions/settingActions';
import TranslateIcon from '@mui/icons-material/Translate';

const LanguageToggler = () => {
    const { language } = useSelector((state) => state.settingReducer)
    const dispatch = useDispatch();

    return (
        <Box sx={{ position: "language", height: "2vh", display: "flex", justifyContent: "right", p: 3 }}>
            <Select
                id="language-select"
                value={language}
                onChange={(e) => dispatch(setLaguage(e.target.value))}
                variant="standard"
                disableUnderline
                dir='ltr'
                startAdornment={
                    <InputAdornment position="start">
                        <TranslateIcon />
                    </InputAdornment>
                }
            >
                <MenuItem value={"en"}>English</MenuItem>
                <MenuItem value={"ar"}>Arabic</MenuItem>
            </Select>
        </Box>
    )
}

export default LanguageToggler