import axios from "axios";
import { toast } from "react-toastify";
import { SET_LOADING } from "../redux/types";

const instance = axios.create({
  baseURL: 'https://645rkp3ig7.execute-api.me-south-1.amazonaws.com/Prod',
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

export async function pay({
  number,
  expiry,
  cvv,
  amount,
  currency,
  invoicesList,
  contactName,
}) {
  const numbers = [];
  invoicesList.forEach((element) => {
    numbers.push({
      id: element.invoice_id,
      number: element.invoiceNumber,
      url: element.pdfUrl,
    });
  });
  try {
    const payment = await instance.post("pay-invoice", {
      number,
      expiry,
      cvv,
      amount: amount.toFixed(2),
      currency,
      contactName,
      entityId: invoicesList[0].entityId,
      entityName: invoicesList[0].entity,
      invoices: numbers,
      contactEmail: invoicesList[0].entityEmail,
    });
    return payment.data;
  } catch (error) {
    toast(error.message, { type: "error" });
    return {
      type: SET_LOADING,
      payload: false,
    };
  }
}

export async function credit({
  number,
  expiry,
  cvv,
  mor_userid,
  mor_username,
  amount,
  customerId,
  currency,
  region,
  contactName,
  contactEmail,
  paid
}) {
  try {
    const response = await instance.post("pay-credit", {
      number,
      expiry,
      cvv,
      amount,
      currency,
      contactName,
      contactEmail,
      customerId,
      mor_userid,
      region,
      mor_username,
      paid
    });
    return response.data;
  } catch (error) {
    toast(error.message, { type: "error" });
    return {
      type: SET_LOADING,
      payload: false,
    };
  }
}

export async function completeCredit({
  payment_intent,
  vat,
  region,
  customerId,
  mor_userid,
  mor_username,
  currency,
  amount,
  contactName,
  paid
}) {
  try {
    const response = await instance.post("capture-credit", {
      payment_intent,
      vat,
      region,
      customerId,
      mor_userid,
      mor_username,
      currency,
      amount,
      contactName,
      paid
    });
    return response.data;
  } catch (error) {
    toast(error.message, { type: "error" });
    return {
      type: SET_LOADING,
      payload: false,
    };
  }
}

export async function completeInvoice({
  payment_intent,
  invoicesList,
  contactName,
}) {
  const numbers = [];
  invoicesList.forEach((element) => {
    numbers.push({
      id: element.invoice_id,
      number: element.invoiceNumber,
      url: element.pdfUrl,
      balance: element.balance,
      currencyBalance: element.currencyBalance,
    });
  });
  try {
    const payment = await instance.post("capture-invoice", {
      payment_intent,
      currency: invoicesList[0].currency,
      contactName,
      entityId: invoicesList[0].entityId,
      entityName: invoicesList[0].entity,
      invoices: numbers,
      contactEmail: invoicesList[0].entityEmail,
    });
    return payment.data;
  } catch (error) {
    toast(error.message, { type: "error" });
    return {
      type: SET_LOADING,
      payload: false,
    };
  }
}
