import {
  Box,
  Typography,
  Stack,
  Button
} from "@mui/material";
import Loader from "../components/loader";
import InvoicesList from "../components/invoicesList";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import ZiwoHeader from "../components/ziwoHeader";
import { FormattedMessage } from "react-intl";
import { formatter } from "../redux/actions/paymentActions";

const PayInvoice = () => {
  const {
    invoicesList,
    loading
  } = useSelector((state) => state.invoicesReducer);
  let navigate = useNavigate();
  const continueFunction = () => {
    navigate("/payment");
  };
  const matches = useMediaQuery('(min-width:665px)');

  return (
    <Box sx={{
      minHeight: "86vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative"
    }}>
      {loading && <Loader />}
      <Stack
        spacing={5}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingY: "2rem",
          paddingBottom: invoicesList.length > 0 ? !matches ? "16rem" : "11.2rem" : "3.2rem",
        }}
      >
        <ZiwoHeader />
        <InvoicesList />
      </Stack>
      <Box
        sx={{
          position: "absolute",
          bottom: 0
        }}>
        {invoicesList.length > 0 && (
          <Box
            sx={{
              background: "#373434",
              display: "flex",
              justifyContent: "space-around",
              color: "#fff",
              py: 2,
              alignItems: "center",
              width: "100vw",
              bottom: "3rem",
              flexWrap: "wrap"
            }}
          >
            <Box sx={{ my: 1 }}>
              <Typography>
                <FormattedMessage id="total_amount" />
              </Typography>
              <Typography>
                {formatter(invoicesList.reduce((acc, obj) => {return acc + parseFloat(obj.currencyBalance)}, 0)) + " " + invoicesList[0].currency}
              </Typography>
              <Typography>
                <FormattedMessage id="include_vat" />
              </Typography>
            </Box>
            <Typography
              sx={{ textAlign: "center", my: 1 }}
            >
              <FormattedMessage id="terms_conditions"/>
            </Typography>
            <Button
              variant="contained"
              sx={{
                fontSize: "20px",
                textTransform: "none",
                my: 1
              }}
              onClick={continueFunction}
            >
              <FormattedMessage id="accept"/>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PayInvoice;
