import {
    SET_LANGUAGE
  }
    from "../types"
  
  const initialState = {
    language: localStorage.getItem('ziwo-qp-lang') || "en"
  }
  
  export function settingReducer(state = initialState, action) {
    // Reducers usually look at the type of action that happened
    // to decide how to update the state
    switch (action.type) {
      case SET_LANGUAGE:
        return { ...state, language: action.payload }
      default:
        // If the reducer doesn't care about this action type,
        // return the existing state unchanged
        return state
    }
  }