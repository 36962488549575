import {
  ADD_INVOICE,
  SET_INVOICE_LIST,
  SET_CHECKOUT_RESPONSE,
  SET_LOADING,
  SET_CUSTOMER,
  SET_AMOUNT,
  SET_CURRENCY,
  SET_PAYMENT_INTENT
}
  from "../types"

const initialState = {
  total: 0,
  invoicesList: [],
  currency: "AED",
  contactName: "",
  contact: {},
  checkoutResponse: {},
  loading: false,
  vatInclude: 0,
  paymentIntent: localStorage.getItem('ziwo-qp-payment_intent')
}

export function paymentReducer(state = initialState, action) {
  // Reducers usually look at the type of action that happened
  // to decide how to update the state
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload }
    case ADD_INVOICE: {
      if (!state.invoicesList.map(i => i.invoiceNumber).includes(action.payload.invoiceNumber)) {
        const list = [...state.invoicesList, action.payload];
        let t = 0;
        list.forEach((item) => {
          t += parseFloat(item.balance);
        });
        return { ...state, invoicesList: list, total: t.toFixed(2), vatInclude: t.toFixed(2), contactName: list[0].entity, currency: "AED" }
      } else {
        return state
      }

    }
    case SET_CUSTOMER:
      return { ...state, contactName: action.payload.name, contact: action.payload }
    case SET_CHECKOUT_RESPONSE:
      return { ...state, checkoutResponse: action.payload, loading: false, invoicesList: [], contact: {}, contactName: "" }
    case SET_INVOICE_LIST: {
      const list = action.payload;
      let t = 0;
      list.forEach((item) => t += parseFloat(item.gross))
      return { ...state, invoicesList: list, total: t.toFixed(2), vatInclude: t.toFixed(2) }
    }
    case SET_AMOUNT:
      return { ...state, total: action.payload.total, vatInclude: action.payload.vatInclude }
    case SET_CURRENCY:
      return { ...state, currency: action.payload }
    case SET_PAYMENT_INTENT:
      return { ...state, paymentIntent: action.payload }
    default:
      // If the reducer doesn't care about this action type,
      // return the existing state unchanged
      return state
  }
}