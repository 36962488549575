import { combineReducers } from "redux";
import { paymentReducer } from "./paymentReducer";
import { settingReducer } from "./settingReducer";
import { invoicesReducer } from "./invoicesReducer";


const rootReducer = combineReducers({
    paymentReducer,
    settingReducer,
    invoicesReducer
})

export default rootReducer;