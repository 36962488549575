import { useState } from "react";
import { getInvoiceApi } from "../api/clearbooksApi";
import { setInvoiceList } from "../redux/actions/paymentActions";
import { toast } from "react-toastify";
import {
  Box,
  Typography,
  Stack,
  Button,
  ButtonBase,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { SET_LOADING } from "../redux/types";
import { formatter } from "../redux/actions/paymentActions";
import { FormattedMessage } from "react-intl";

const InvoicesList = () => {
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { invoicesList } = useSelector((state) => state.paymentReducer);

  const checkInvoice = async (index) => {
    if (!invoiceNumber) {
      setErrors({ [index]: "Please fill in this field" });
      return;
    }
    if (!invoicesList.map((i) => i.invoiceNumber).includes(invoiceNumber)) {
      dispatch({ type: SET_LOADING, payload: true });
      dispatch(await getInvoiceApi(invoiceNumber, invoicesList));
      setInvoiceNumber("");
      dispatch({ type: SET_LOADING, payload: false });
    } else {
      toast("duplicated_invoice", { type: "error" });
    }
  };

  const onChangeInvoiceField = (event) => {
    let val = event.target.value.slice(0, 15).replace(/[^0-9/]/g, '');
    // if (val.length >= 5 && !val.includes("/")) {
    //   //val = val.substring(0, 4) + "/" + val.substring(4, val.length);
    // } this is odoo invoice number format
    setInvoiceNumber(val);
    if (val) setErrors({});
  };

  const removeInvoice = (index) => {
    const list = [...invoicesList];
    list.splice(index, 1);
    dispatch(setInvoiceList(list));
  };

  const InvoiceDetails = ({ invoice, index }) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        px: "25%",
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          my: 1,
        }}
      >
        <Typography>
          <FormattedMessage id="invoice_number" />
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: "primary.main",
            }}
          >
            {invoice.invoice_prefix + invoice.invoiceNumber}
          </Typography>
          <ButtonBase onClick={() => removeInvoice(index)}>X</ButtonBase>
        </Box>
      </Box>
      <Box
        sx={{
          my: 1,
        }}
      >
        <Typography>
          <FormattedMessage id="total_amount_vat" />
        </Typography>
        <Typography
          sx={{
            color: "primary.main",
          }}
        >
          {formatter(invoice.currencyBalance) + " " + invoice.currency}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        width: "100vw",
        background: "#F7F8F4",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: "45vh",
      }}
    >
      {invoicesList.length === 0 ? (
        <Stack
          spacing={1}
          sx={{
            width: "50%",
            py: 4,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "500",
              color: "primary.main",
            }}
          >
            <FormattedMessage id="your_ziwo_account" />
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            <FormattedMessage id="enter_invoice" />
          </Typography>
          <TextField
            variant="standard"
            type="text"
            autoFocus
            onChange={onChangeInvoiceField}
            helperText={
              errors[invoicesList.length] ? errors[invoicesList.length] : ""
            }
            FormHelperTextProps={{
              sx: { color: !errors[invoicesList.length] ? "#00000080" : "red" },
            }}
            InputProps={{
              inputProps: { min: 1 },
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      color: "primary.light",
                      fontSize: "16px",
                    }}
                  >
                    <FormattedMessage id={"INV"} />
                  </Typography>
                </InputAdornment>
              ),
            }}
            value={invoiceNumber}
            sx={{
              width: "100%",
              color: "primary.light",
              fontSize: "16px",
              input: { color: "#AD3278" },
            }}
          />
          <Typography
            sx={{
              color: "#6D7278",
              fontSize: "16px",
            }}
          >
            <FormattedMessage id="invoice_example" />
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "50%",
                fontSize: "20px",
                ":disabled": { background: "#9C2669B3", color: "white" },
              }}
              onClick={() => checkInvoice(0)}
              disabled={!invoiceNumber}
            >
              <FormattedMessage id="pay" />
            </Button>
          </Box>
        </Stack>
      ) : (
        <Stack
          spacing={2}
          sx={{
            width: "100%",
            py: 2,
          }}
        >
          {invoicesList.map((item, idx) => (
            <InvoiceDetails invoice={item} key={idx} index={idx} />
          ))}
          {invoicesList.length < 5 && (
            <Box sx={{ px: "25%" }}>
              <Typography sx={{ fontSize: "14px" }}>
                <FormattedMessage id="add_invoices" />
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <TextField
                  variant="standard"
                  type="text"
                  autoFocus
                  onChange={onChangeInvoiceField}
                  helperText={
                    errors[invoicesList.length]
                      ? errors[invoicesList.length]
                      : ""
                  }
                  FormHelperTextProps={{
                    sx: {
                      color: !errors[invoicesList.length] ? "#00000080" : "red",
                    },
                  }}
                  InputProps={{
                    inputProps: { min: 1 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          sx={{
                            color: "primary.light",
                            fontSize: "16px",
                          }}
                        >
                          <FormattedMessage id={"INV"} />
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  value={invoiceNumber}
                  sx={{
                    width: "30%",
                    color: "primary.light",
                    fontSize: "16px",
                    input: { color: "#AD3278" },
                  }}
                />

                <ButtonBase
                  sx={{
                    background: "#8BC34A",
                    borderRadius: "1.5rem",
                    color: "#fff",
                    width: "3rem",
                    height: "3rem",
                    fontSize: "2rem",
                    mx: "2rem",
                  }}
                  onClick={() => checkInvoice(invoicesList.length)}
                >
                  +
                </ButtonBase>
              </Box>
              <Typography
                sx={{
                  color: "#6D7278",
                  fontSize: "16px",
                }}
              >
                <FormattedMessage id="invoice_example" />
              </Typography>
            </Box>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default InvoicesList;
