import {
  Typography,
  Stack,
  Button,
  Box,
  InputLabel,
  NativeSelect,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setAmount, setCurrency } from "../redux/actions/creditAction"
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from "react";
import NumberInput from "../components/numberInput";
import { FormattedMessage } from "react-intl";
import ZiwoHeader from "../components/ziwoHeader";

const CreditAmount = () => {
  const dispatch = useDispatch();
  const { contact, currency } = useSelector((state) => state.paymentReducer)
  const [showInput, setShowInput] = useState(false)
  const [creditValue, setcreditValue] = useState(500)
  const [options, setOptions] = useState(currency === 'AED' ?
    ['300', '500', '1,000', '3,000', '5,000', '10,000'] : ['100', '200', '300', '500', '800', '1,000', '3,000', '5,000', '7,000'])
  let navigate = useNavigate();
  const [error, setError] = useState('')

  const handlePayButton = () => {
    const vat = contact && contact.default_vat_rate ? contact.default_vat_rate : 0;
    dispatch(setAmount(creditValue, vat))
    if (parseFloat(creditValue) < options[0]) {
      setError("amount_error_message")
      return;
    }
    navigate("/credit-payment");
  };

  useEffect(() => {
    // if credit amount is fine just show placeholder
    if (parseFloat(creditValue) > options[0]) {
      setError('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditValue])

  const onChangeValue = (val) => {
    setShowInput(val === ' ')
    setcreditValue(val)
  }

  const changeValueInput = (event) => {
    const v = event.target.value.replaceAll("e", "").slice(0, 10);
    setcreditValue(v)
  }

  const handleChangeCurrency = (event) => {
    dispatch(setCurrency(event.target.value))
    if (event.target.value === 'AED') {
      setOptions(['300', '500', '1,000', '3,000', '5,000', '10,000'])
      setcreditValue('500')
    } else {
      setOptions(['100', '200', '300', '500', '800', '1,000', '3,000', '5,000', '7,000'])
      setcreditValue('200')
    }
    setShowInput(false)
  }

  return (
    <Box sx={{
      minHeight: "86vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      position: "relative",
      flexDirection: "column",
    }}>
      <Stack
        spacing={5}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ZiwoHeader />
      </Stack>
      <Box
        sx={{
          width: "100vw",
          background: "#F7F8F4",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py:4
        }}
      >
        <Box sx={{
          width: "30%",
          margin: "auto"
        }}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "500",
              color: "primary.main",
              mb: 1
            }}
          >
            <FormattedMessage id={"add_credit"} />
          </Typography>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={3}
          >
            <Box sx={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap"
            }}>
              <FormControl variant="standard"
                sx={{
                  width: "60px",
                  mr: 2
                }}>
                <InputLabel >
                  <FormattedMessage id={"currency"} />
                </InputLabel>
                <Select
                  value={currency}
                  onChange={e => handleChangeCurrency(e)}
                  label="Currency"
                >
                  <MenuItem value={"AED"}>AED</MenuItem>
                  <MenuItem value={"USD"}>USD</MenuItem>
                  <MenuItem value={"EUR"}>EUR</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: "calc(100% - 80px)" }}>
                <InputLabel variant="standard"
                  htmlFor="uncontrolled-native"
                  sx={{ overflow: "visible" }}>
                  <FormattedMessage id={"enter_amount"} />
                </InputLabel>
                <NativeSelect
                  defaultValue={options[1]}
                  onChange={event => onChangeValue(event.target.value)}
                  inputProps={{
                    name: "amout",
                    id: "uncontrolled-native",
                  }}
                >
                  {
                    options.map((option, i) =>
                      <option value={option.replaceAll(',', '')} key={i}>{option}</option>
                    )
                  }
                  <option value={' '}>
                    other
                  </option>
                </NativeSelect>
              </FormControl>
            </Box>
            {showInput &&
              <NumberInput
                value={creditValue}
                error={error ? <FormattedMessage id={error} values={{ a: options[0] }} /> : ""}
                changeValueInput={changeValueInput}
                label={<FormattedMessage id={"enter_amount"} />}
                preFix={currency}
                helperText={<FormattedMessage id="minimum_amount" values={{ a: options[0] }} />}
              />
            }
            <Button
              variant="contained"
              onClick={handlePayButton}
              sx={{
                fontSize: "20px",
                marginLeft: "10px",
                width: "60%",
                ":disabled": { background: "#9C2669B3", color: "white" }
              }}
              disabled={creditValue === ' ' || !creditValue}
            >
              <FormattedMessage id={"pay"} />
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default CreditAmount;
