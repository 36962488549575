import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

const ZiwoHeader = () => {

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }}>
            <img
                src={"/images/ziwo-logo.png"}
                alt="logo"
                style={{ height: "75px" }}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography sx={{ fontSize: "36px", fontWeight: "500" }}>
                    <FormattedMessage id="headLine" />
                </Typography>
                <Typography sx={{ fontSize: "20px", color: "primary.light" }}>
                    <FormattedMessage id="pay_instantly" />
                </Typography>
                <Typography sx={{ fontSize: "20px", fontWeight: "300" }}>
                    <FormattedMessage id="anywhere_anytime" />
                </Typography>
            </Box>
        </Box>
    )
}

export default ZiwoHeader