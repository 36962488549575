import {
  Typography,
  Stack,
  Button,
  Box,
  TextField,
  Tooltip,
  InputAdornment,
  Link
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  useDispatch,
  useSelector
} from 'react-redux'
import {
  SET_CUSTOMER,
  SET_LOADING
} from "../redux/types";
import { getCustomerApi } from "../api/clearbooksApi";
import Loader from "../components/loader";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormattedMessage } from "react-intl";
import ZiwoHeader from "../components/ziwoHeader";

const TopUpCredit = () => {

  const [accountName, setAccountName] = useState("");
  const [toggleView, setToggleView] = useState(true);


  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loading
  } = useSelector((state) => state.paymentReducer);
  const handleProceedButton = async () => {
    dispatch({
      type: SET_LOADING,
      payload: true
    })
    const account = await getCustomerApi(accountName);
    if (!!account) {
      dispatch({
        type: SET_CUSTOMER,
        payload: account
      })
      navigate("/credit-amount");
    }
    dispatch({
      type: SET_LOADING,
      payload: false
    })

  };

  return (
    <Box sx={{
      minHeight: "86vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      position: "relative",
      flexDirection: "column"
    }}>
      {loading && <Loader />}
      <Stack
        spacing={5}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ZiwoHeader/>
      </Stack>
      <Box
        sx={{
          width: "100vw",
          background: "#F7F8F4",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {toggleView ?
          <Box
            sx={{
              minWidth: "30%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              py:4
            }}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "500",
                color: "primary.main",
                fontFamily: "Roboto"
              }}
            >
              <FormattedMessage id={"enter_account"}/>
            </Typography>
            <TextField
              variant="standard"
              value={accountName}
              onChange={(event) => setAccountName(event.target.value)}
              sx={{
                width: "100%",
                color: "primary.light",
                fontSize: "16px",
                input: { color: "#000" },
                mt: 1
              }}
              type="text"
              name="name"
              label={<FormattedMessage id={"account"}/>}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      placement="top-start"
                      title={<FormattedMessage id={"get_account_name"}/>}
                      sx={{ pr: 2, cursor: "help" }}
                    >
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </InputAdornment>
                )
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                marginTop: "8px",
                marginBottom: "24px"
              }}>
              <Button
                sx={{
                  display: "flex",
                  width: "fit-content",
                }}
                onClick={() => setToggleView(false)}>
                <FormattedMessage id={"what_my_account"}/>
              </Button>
            </Box>
            <Button
              variant="contained"
              onClick={handleProceedButton}
              sx={{
                fontSize: "20px",
                mx: 4,
                ":disabled": { background: "#9C2669B3", color: "white" }
              }}
              disabled={!accountName}
            >
              <FormattedMessage id={"proceed"}/>
            </Button>
          </Box> :
          <Stack
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              maxWidth: "400px",
              py:4
            }}>
            <Typography
              sx={{
                color: "#000000DE",
                fontFamily: 'Roboto',
                fontSize: "24px",
                fontWeight: "500",
                textAlign: "center"
              }}>
              <FormattedMessage id={"what_my_account"}/>
            </Typography>
            <img
              src={"/images/account-name.png"}
              alt="account-information"
              style={{ height: "90px", objectFit: 'contain' }}
            />
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "16px",
                textAlign: "center",
                color: '#00000080'
              }}>
              <FormattedMessage id={"your_account"}  values={{br: <br/>}}/>
              <Link href="https://app.ziwo.io/login" target="_blank"
                underline='always'
                sx={{
                  cursor: "pointer",
                  color: "#045295",
                  textDecorationColor: "#045295",
                }}> https://app.ziwo.io/login</Link>
            </Typography>
            <Button
              variant="contained"
              onClick={() => setToggleView(true)}
              sx={{
                fontSize: "20px",
                marginX: "4rem !important"
              }}>
              <FormattedMessage id={"great_thanks"}/>
            </Button>
          </Stack>
        }
      </Box>
    </Box>
  );
};

export default TopUpCredit;
