import axios from "axios";
import { toast } from "react-toastify";
import {
  ADD_INVOICE,
  SET_LOADING
} from "../redux/types";

const instance = axios.create({
  baseURL: 'https://tzdub274u4.execute-api.me-south-1.amazonaws.com/Prod',
  timeout: 30000
});

export async function getInvoiceApi(invoiceNumber, invoicesList) {
  try {
    const response = await instance.get('get-invoice', { params: { number: invoiceNumber } })

    if (response.data.result) {
      let newInvoice = response.data.content;
      
      if (invoicesList.length !== 0) {
        if (newInvoice.entityId === invoicesList[0].entityId && newInvoice.currency === invoicesList[0].currency)
          return { type: ADD_INVOICE, payload: newInvoice };
        else
          throw new Error(newInvoice.entityId !== invoicesList[0].entityId? 'All Invoices have to be for the same customer' : 'All Invoices have to be for the same currency');
      } else {
        return { type: ADD_INVOICE, payload: newInvoice };
      }
    } else {
      throw new Error(response.data.info ? response.data.info.message : "Unknown error")
    }
  } catch (error) {
    toast(error.message, { type: 'error' });
    return { type: SET_LOADING, payload: false }
  }
}

export async function getCustomerApi(customerName) {
  try {
    const response = await instance.get('get-customer', { params: { customer: customerName } })
    if(response.data.result){
      return response.data.content
    }else{
      throw new Error(response.data.info? response.data.info.message : "Unknown error")
    }
  } catch (error) {
    toast(error.message, { type: 'error' });
    return null;
  }
}