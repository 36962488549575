import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Payment from "./routes/payment";
import CreditPayment from "./routes/creditPayment";
import Success from "./routes/success";
import { Provider } from "react-redux";
import store from "./redux/store";
import PayInvoice from "./routes/invoiceFlow";
import TopUpCredit from "./routes/topUpCredit";
import CreditAmount from "./routes/creditAmount";
import "./index.css";
import "./fonts/index.css";
import CustemFooter from "./components/footer";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import English from "./lang/en.json";
import Arabic from "./lang/ar.json";
import LanguageToggler from "./components/languageToggler";
import CompletePayment from "./routes/completePayment";

const palette = {
  primary: { main: "#9C2669", light: "#AD3278" },
};

document.getElementById("root").style.position = "relative";
document.getElementById("root").style.minHeight = "95vh";
document.getElementById("root").style.paddingBottom = "5vh";
export const isInMaintenance = false;

const Index = () => {
  const { language } = useSelector((state) => state.settingReducer);
  document.getElementsByTagName("html")[0].setAttribute("dir", language === "ar" ? "rtl" : "ltr");

  const messages = {
    en: English,
    ar: Arabic,
  };

  return (
    <IntlProvider messages={messages[language]} locale={language} defaultLocale="en">
      <BrowserRouter sx={{ position: "relative" }}>
        <LanguageToggler />

        {isInMaintenance ? (
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="*" element={<div>404</div>}></Route>
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<App />} />

            <Route path="/pay-invoice" element={<PayInvoice />} />
            <Route path="/top-up-credit" element={<TopUpCredit />} />
            <Route path="/credit-amount" element={<CreditAmount />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/credit-payment" element={<CreditPayment />} />
            <Route path="/complete-payment" element={<CompletePayment />} />
            <Route path="/success" element={<Success />} />
            <Route path="*" element={<div>404</div>}></Route>
          </Routes>
        )}

        <ToastContainer />
        <CustemFooter />
      </BrowserRouter>
    </IntlProvider>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider
      theme={createTheme({
        palette: palette,
      })}>
      <Index />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
