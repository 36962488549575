import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';


const CustemFooter = () => (
    <Box sx={{ backgroundColor: "primary.main", width: "100vw", display: "flex", justifyContent: "center", height: "5vh", alignItems: "center", position: "fixed", bottom: 0, zIndex: "2"}}>
        <Typography sx={{ color: "#fff" }}>
        <FormattedMessage id="copyright"/>
        </Typography>
    </Box>
)

export default CustemFooter;