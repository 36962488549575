import { Box } from "@mui/material";
import { setPaymentIntent } from "../redux/actions/creditAction";
import queryString from "query-string";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Loader from "../components/loader";

const CompletePayment = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    callAPI(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callAPI = async () => {
    const queryParams = queryString.parse(window.location.search);
    const payment_intent = !queryParams["payment_intent"]
      ? queryParams["cko-session-id"]
      : queryParams["payment_intent"];
    // console.log("queryParams:", queryParams);
    dispatch(setPaymentIntent(payment_intent));
    window.top.postMessage("3DS-authentication-complete");
  };

  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        zIndex: 10,
        background: "white",
      }}
    >
      <Loader />
    </Box>
  );
};

export default CompletePayment;
