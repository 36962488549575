import {
    SET_AMOUNT,
    SET_CURRENCY,
    SET_PAYMENT_INTENT
} from "../types";

export function setAmount(value, vat) {
    const parse = parseFloat(value).toFixed(2)
    const t = parseFloat(value) + parseFloat(value * vat);
    const parseT = parseFloat(t).toFixed(2)
    return { type: SET_AMOUNT, payload: {total: parse, vatInclude: parseT} };
}

export function setCurrency(value) {
    return { type: SET_CURRENCY, payload: value };
}

export function setPaymentIntent(value) {
    localStorage.setItem('ziwo-qp-payment_intent', value)
    return { type: SET_PAYMENT_INTENT, payload: value };
}