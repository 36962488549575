import {
    ADD_INVOICE,
    SET_INVOICE_LIST
} from "../types";

export function addInvoice(invoice) {
    return { type: ADD_INVOICE, payload: invoice };
}

export function setInvoiceList(list) {
    return { type: SET_INVOICE_LIST, payload: list };
}
export function formatter(number) {
    number = number.toString()
    if (!number)
        return;
    let arr = number.split('.')
    if (arr.length === 1)
        arr[1] = "00"
    else if (arr[1]) {
        arr[1] = arr[1].length === 1 ? arr[1] + "0" : arr[1].substring(0, 2)
    }
    if (arr[0].length > 3) {
        var r = [];
        for (let i = arr[0].length; i > 0; i -= 3) {
            let start = i - 3 > 0 ? i - 3 : 0; 
            let end = i - 3 > 0 ? 3 : i;
            r.unshift(arr[0].substr(start, end));
        }
        arr[0] = r
    }

    return arr[0] + "." + arr[1]
}