import {
  Box,
  Typography,
  Stack,
  TextField,
  Button
} from '@mui/material';
import {
  credit,
  completeCredit
} from '../api/checkout';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import {
  useState,
  useEffect
} from "react"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  SET_CHECKOUT_RESPONSE,
  SET_CUSTOMER,
  SET_LOADING
} from '../redux/types';
import Loader from '../components/loader';
import { formatter } from "../redux/actions/paymentActions";
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormattedMessage } from 'react-intl';
import { setPaymentIntent } from '../redux/actions/creditAction';

const CreditPayment = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:780px)');

  const {
    total,
    contactName,
    currency,
    loading,
    contact,
    vatInclude,
    paymentIntent
  } = useSelector((state) => state.paymentReducer)

  const [state, setState] = useState({
    number: "",
    cvv: "",
    expiry: ""
  })
  const [errors, setErrors] = useState({
    number: "",
    cvv: "",
    expiry: ""
  })

  useEffect(() => {
    if (contactName === "")
      navigate("/")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // handle 3D secure payment result
  useEffect(() => {
    const iframe = document.getElementById("3d-auth");
    if (iframe) {
      iframe.remove();
      completePayment()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIntent])

  window.addEventListener('message', function (ev) {
    if (ev.data === '3DS-authentication-complete') 
      dispatch(setPaymentIntent(localStorage.getItem('ziwo-qp-payment_intent')))
  }, false);

  const completePayment = async () => {
    const response = await completeCredit({
      ...contact,
      payment_intent: paymentIntent,
      vat: contact.default_vat_rate || 0,
      currency,
      contactName,
      amount: total,
      contactEmail: contact.email,
      customerId: contact.odooId,
      paid: vatInclude
    })

    if (response.result === true) {
      dispatch({ type: SET_CUSTOMER, payload: false })
      dispatch(setPaymentIntent(null))
      console.log({ response })
      dispatch({ type: SET_CHECKOUT_RESPONSE, payload: response.content.checkout })
      navigate("/success")
    } else {
      toast(
        <FormattedMessage id={response.info.message} />,
        { type: 'error' }
      );
    }
    dispatch({ type: SET_LOADING, payload: false })
  }

  const handleChangeNumber = (event) => {
    let noSpaces = event.target.value.replaceAll(' ', '');
    if (!isNaN(+noSpaces) && noSpaces.length <= 16) {
      let val = event.target.value.trim();
      if (noSpaces.length % 4 === 1 && noSpaces.length < 16) {
        val = val.substring(0, val.length - 1)
          + " " +
          val.substring(val.length - 1, val.length)
      }
      setState({
        ...state,
        number: val
      })
      setErrors({
        ...errors,
        number: ""
      })
    }
  }

  const handleChangeExpiry = (event) => {
    let val = event.target.value;
    if (!isNaN(+val.replaceAll('/', '')) && val.length <= 5) {
      if (val.length === 3 && !val.includes('/')) {
        val = val.substring(0, val.length - 1)
          + "/" +
          val.substring(val.length - 1, val.length)
      }
      setState({
        ...state, expiry: val
      })
      setErrors({
        ...errors,
        expiry: ""
      })
    }
  }

  const handleChangeCvv = (event) => {
    let val = event.target.value;
    if (!isNaN(+val) && val.length <= 3) {
      setState({
        ...state, cvv: val
      })
      setErrors({
        ...errors,
        cvv: ""
      })
    }
  }

  const handlePay = async () => {
    if (!state.number || !state.expiry || !state.cvv) {
      setErrors({
        number: state.number ? "" : "required_field",
        expiry: state.expiry ? "" : "required_field",
        cvv: state.cvv ? "" : "required_field"
      })
      return;
    }
    let noSpaces = state.number.replaceAll(' ', '');
    if (noSpaces.length < 16) {
      setErrors({
        number: 'invalid_card_number',
      })
      return;
    }
    let response;
    dispatch({ type: SET_LOADING, payload: true })
    if (contact.region && contact.region.url) {
      response = await credit({
        ...state,
        currency,
        contactName,
        number: state.number.replaceAll(' ', ''),
        amount: total,
        contactEmail: contact.email,
        customerId: contact.odooId,
        ...contact,
        paid: vatInclude
      })
    } else {
      toast(<FormattedMessage id={"general_error"} />, { type: 'error' });
    }
    if (response.result === true) {
      if (response.content.checkout.next_action && response.content.checkout.next_action.redirect_to_url) {
        // window.location = response.content.checkout.next_action.redirect_to_url.url;
        var iframe = document.createElement('iframe');
        iframe.src = response.content.checkout.next_action.redirect_to_url.url;
        iframe.width = "80%";
        iframe.height = "80%";
        iframe.id = "3d-auth";
        iframe.style.position = "absolute"
        iframe.style.top = "10vh"
        iframe.style.left = "10vw"
        iframe.style.border= "2px solid #0000003d";
        iframe.style.borderRadius= "16px";
        iframe.style.zIndex = "20"
        document.getElementById("container").appendChild(iframe);
      } else {
        dispatch({ type: SET_LOADING, payload: false })
        dispatch({ type: SET_CUSTOMER, payload: false })
        console.log({ response })
        dispatch({ type: SET_CHECKOUT_RESPONSE, payload: response.content.checkout })
        navigate("/success")
        console.log("INSIDE ELSE")
      }
    } else {
      dispatch({ type: SET_LOADING, payload: false })
      toast(
        <FormattedMessage id={response.info.message} />,
        { type: 'error' }
      );
    }
  }

  return (
    <Box
      id="container"
      sx={{
        minHeight: "86vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
      }}>
      {loading && <Loader />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "75vh",
        }}>
        <Stack spacing={5}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}>
          <img src={"/images/ziwo-logo.png"}
            alt="logo"
            style={{ height: "75px" }} />
          <Box sx={{
            display: "flex",
            width: "60%",
            justifyContent: "center",
            flexDirection: matches ? "row" : "column-reverse",
          }}>
            <Stack
              spacing={3}
              sx={{
                width: matches ? "40%" : "100%",
                mt: matches ? 0 : 4
              }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "500",
                }}>
                <FormattedMessage id={"payment_options"} />
              </Typography>
              <TextField
                variant="standard"
                type="text"
                onChange={handleChangeNumber}
                value={state.number}
                helperText={errors.number ? <FormattedMessage id={errors.number} /> : ""}
                label={<FormattedMessage id="card_number" />}
                sx={{
                  width: "100%",
                  color: "primary.light",
                  fontSize: "14px",
                  input: { color: '#AD3278' },
                }}
                FormHelperTextProps={{ sx: { color: "red" } }} />
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between"
                }}>
                <TextField
                  variant="standard"
                  value={state.expiry}
                  onChange={handleChangeExpiry}
                  helperText={errors.expiry ? <FormattedMessage id={errors.expiry} /> : ""}
                  label={<FormattedMessage id="expiry_date" />}
                  sx={{
                    width: "45%",
                    color: "primary.light",
                    fontSize: "14px",
                    input: { color: '#AD3278' }
                  }}
                  FormHelperTextProps={{ sx: { color: "red" } }} />
                <TextField
                  variant="standard"
                  type="text"
                  value={state.cvv}
                  onChange={handleChangeCvv}
                  helperText={errors.cvv ? <FormattedMessage id={errors.cvv} /> : ""}
                  label={<FormattedMessage id="security_code" />}
                  sx={{
                    width: "45%",
                    color: "primary.light",
                    fontSize: "14px",
                    input: { color: '#AD3278' }
                  }}
                  FormHelperTextProps={{ sx: { color: "red" } }} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center"
                }}>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: "20px",
                    textTransform: 'none',
                    width: "80%",
                    mt: 2,
                    ":disabled": { background: "#9C2669B3", color: "white" }
                  }}
                  onClick={handlePay}
                  disabled={!state.number || state.expiry.length < 5 || state.cvv.length < 3}
                >
                  <FormattedMessage id={"pay"} />
                </Button>
              </Box>
            </Stack>
            <Box sx={{ borderRight: matches ? "1px solid #0000001F" : "", mx: 10 }}>

            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: matches ? "40%" : "100%",
              }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "500",
                  marginBottom: "24px"
                }}>
                <FormattedMessage id={"payment_summary"} />
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontFamily: "Roboto",
                  mb: 2
                }}>
                <Typography sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                }}>
                  <FormattedMessage id={"account_name"} />
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#00000080"
                  }}>
                  {contactName}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontFamily: "Roboto",
                  mb: 2
                }}>
                <Typography sx={{
                  fontSize: "14px",
                  fontWeight: "300",
                }}>
                  <FormattedMessage id={"topup_amount"} />
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#00000080"
                  }}>
                  {formatter(total)} {currency}
                </Typography>
              </Box>

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontFamily: "Roboto",
                    mb: 2,
                  }}>
                  <Typography sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}>
                    <FormattedMessage id={"vat"} />
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#00000080"
                    }}>
                    {!!(contact.default_vat_rate * 100) ? contact.default_vat_rate * 100 + "%" : "-"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontFamily: "Roboto",
                    paddingTop: 2,
                    borderTop: "1px solid #0000001F",
                  }}>
                  <Typography sx={{
                    fontSize: "14px",
                    fontWeight: "300",
                  }}>
                    <FormattedMessage id={"payment_amount"} />
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#00000080"
                    }}>
                    {formatter(vatInclude)} {currency}
                  </Typography>
                </Box>
              </Box>

            </Box>
          </Box>

        </Stack>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}>
        <Typography
          sx={{
            fontSize: "14px",
            mb: 1
          }}>
          <FormattedMessage id={"accepted_cards"} />
        </Typography>
        <img src={"/images/pay-by.png"}
          alt="success" style={{
            objectFit: "scale-down",
            height: "20px",

          }} />
      </Box>
    </Box>
  )
}

export default CreditPayment