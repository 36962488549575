
import {
    Typography,
    TextField,
    InputAdornment
} from "@mui/material";
import { FormattedMessage } from "react-intl";

const NumberInput = (props) => {

    const { error, changeValueInput, label, preFix, value, helperText, customSX } = props;

    return (
        <TextField
            value={value}
            variant="standard"
            sx={{
                width: "100%",
                color: "primary.light",
                fontSize: "16px",
                input: { color: '#AD3278' },
                ...customSX
            }}
            autoFocus
            type="number"
            helperText={error ? error : helperText}
            FormHelperTextProps={{ sx: { color: !error ? "#00000080" : "red" } }}//TODO
            onChange={
                event => changeValueInput(event)
            }
            label={label}
            onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+' || event?.key === 'e') {
                    event.preventDefault();
                }
            }}
            InputProps={{
                inputProps: { min: 1 },
                startAdornment: (
                    <InputAdornment position="start">
                        <Typography sx={{
                            color: "primary.light",
                            fontSize: "16px"
                        }}>
                            <FormattedMessage id={preFix}/>
                        </Typography>
                    </InputAdornment>
                )
            }}
        />
    )
}

export default NumberInput